import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { error } from "src/app/_helpers/error";
import { User } from "../../models/user.model";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  submitted: boolean = false;
  error = error.err;
  logo: string = "../../../../assets/images/auth/Admin.svg";

  userType: any = 99;

  check: number = 1;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
    password: new UntypedFormControl("", [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  ngOnInit() {}

  get f() {
    return this.loginForm.controls;
  }

  onAdminSelection(event: any) {
    if (event.target.checked == true) {
      this.userType = 99;
      this.check = 1;
    }
  }

  onSupportSelection(event: any) {
    if (event.target.checked == true) {
      this.userType = 5;
      this.check = 2;
    }
  }

  onZoneSelection(event: any) {
    if (event.target.checked == true) {
      this.userType = 6;
      this.check = 3;
    }
  }
  assignUser(type) {
    this.userType = type;
    if (type == 1) {
      this.logo = "../../../../assets/images/auth/Admin.svg";
    } else if (type == 7) {
      this.logo = "../../../../assets/images/auth/Sub-admin.svg";
    }
  }
  login() {
    this.submitted = true;
    if (this.loginForm.invalid || this.loginForm.pristine) return;
    this.submitted = false;
    let user: any = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };

    this.authService.login(user).subscribe(
      (res: any) => {
        if (res.status == true) {
          localStorage.setItem("currentUser", JSON.stringify(res.result));
          localStorage.setItem("lang", "eng");

          if (res.result.seasson && res.result.seasson.length > 0)
            if (res.result.userType == 99) {
              this.router.navigateByUrl("/season");
            } else if (res.result.userType == 5) {
              this.router.navigateByUrl("/support/requests");
            } else if (res.result.userType == 6) {
              this.router.navigateByUrl("/zone-managers/request");
            } else {
              this.router.navigateByUrl("/events");
            }
        } else {
          this.toastr.error(res.message);
        }
      },
      (err) => {
        this.toastr.error(this.error);
      }
    );
  }
}
